import {
  LogoIcon_default
} from "./chunk-KTAWEVV6.mjs";
import {
  Menu01Icon_default
} from "./chunk-HDOHMLLE.mjs";
import {
  LayoutStyles
} from "./chunk-BNUCZ43G.mjs";
import {
  DrawerForUnderLargeSize_default
} from "./chunk-OQW6M35V.mjs";
import {
  Sidebar_default
} from "./chunk-7CSKXFIP.mjs";

// src/antd/AppShellCore.tsx
import React, { useState } from "react";
import { CloseOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Layout, Space, Typography } from "antd";
var { Header, Content } = Layout;
var { Text } = Typography;
var AppShellCore = ({
  children,
  sideLinks = [],
  profile,
  signout = () => {
  },
  breadcrumb,
  goToHome = () => {
  },
  notification,
  renderChat
}) => {
  const [isOpenDrawer, setOpenDrawer] = useState(false);
  const onOpenDrawer = () => {
    setOpenDrawer(true);
  };
  const onCloseDrawer = () => {
    setOpenDrawer(false);
  };
  return /* @__PURE__ */ React.createElement(LayoutStyles, {
    hasSider: true
  }, (sideLinks == null ? void 0 : sideLinks.length) > 0 && /* @__PURE__ */ React.createElement(Sidebar_default, {
    sideLinks,
    goToHome
  }), /* @__PURE__ */ React.createElement(Layout, {
    className: (sideLinks == null ? void 0 : sideLinks.length) > 0 ? "right-side" : "right-side-ml-0"
  }, /* @__PURE__ */ React.createElement(Header, {
    style: { height: "72px" }
  }, /* @__PURE__ */ React.createElement("div", {
    onClick: goToHome
  }, /* @__PURE__ */ React.createElement(LogoIcon_default, {
    className: (sideLinks == null ? void 0 : sideLinks.length) ? "header-small-size" : void 0
  })), /* @__PURE__ */ React.createElement("a", {
    onClick: (e) => e.preventDefault(),
    className: "header-large-size"
  }, /* @__PURE__ */ React.createElement(Space, {
    size: "large"
  }, renderChat, notification, /* @__PURE__ */ React.createElement(Space, null, /* @__PURE__ */ React.createElement(Avatar, {
    style: { backgroundColor: "#87d068" },
    icon: /* @__PURE__ */ React.createElement(UserOutlined, null)
  }), /* @__PURE__ */ React.createElement(Text, null, (profile == null ? void 0 : profile.displayName) || (profile == null ? void 0 : profile.email))), /* @__PURE__ */ React.createElement(Button, {
    onClick: signout
  }, "Sign out"))), /* @__PURE__ */ React.createElement(Button, {
    className: "header-small-size",
    onClick: () => isOpenDrawer ? onCloseDrawer() : onOpenDrawer(),
    icon: isOpenDrawer ? /* @__PURE__ */ React.createElement(CloseOutlined, null) : /* @__PURE__ */ React.createElement(Menu01Icon_default, null)
  })), /* @__PURE__ */ React.createElement(Layout, null, /* @__PURE__ */ React.createElement(Content, null, /* @__PURE__ */ React.createElement("div", {
    className: "site-layout-background"
  }, breadcrumb, /* @__PURE__ */ React.createElement(DrawerForUnderLargeSize_default, {
    sideLinks,
    isOpen: isOpenDrawer,
    onClose: onCloseDrawer
  }), children)))));
};
var AppShellCore_default = AppShellCore;

export {
  AppShellCore_default
};
