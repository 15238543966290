import {
  Logo_default
} from "./chunk-GJXAGNTJ.mjs";
import {
  MenuWrap,
  SidebarWrap
} from "./chunk-3YHHGYL6.mjs";

// src/antd/Component/Sidebar/index.tsx
import React, { useMemo } from "react";
import { useRouter } from "next/router";
function Sidebar({
  collapsed,
  sideLinks = [],
  goToHome = () => {
  }
}) {
  const router = useRouter();
  const onSelectMenu = (path) => {
    router.push(path);
  };
  const defaultOpenKeys = useMemo(() => {
    const splitPathname = router.pathname.split("/");
    if ((splitPathname == null ? void 0 : splitPathname.length) === 3) {
      return [`/${splitPathname[1]}`];
    }
    return [router.pathname];
  }, [router.pathname]);
  return /* @__PURE__ */ React.createElement(SidebarWrap, {
    className: "sidebar",
    trigger: null,
    collapsible: true,
    collapsed
  }, /* @__PURE__ */ React.createElement(Logo_default, {
    onClick: goToHome
  }), /* @__PURE__ */ React.createElement(MenuWrap, {
    onClick: (item) => onSelectMenu(item.key),
    mode: "inline",
    theme: "dark",
    selectedKeys: [router.pathname],
    defaultOpenKeys,
    items: sideLinks
  }));
}
var Sidebar_default = Sidebar;

export {
  Sidebar_default
};
