import {
  __spreadValues
} from "./chunk-NXJS66U5.mjs";

// src/antd/icons/LogoIcon.tsx
import React from "react";
import Icon from "@ant-design/icons";
var LogoIconSvg = () => {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "152",
    height: "38",
    viewBox: "0 0 152 38",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, /* @__PURE__ */ React.createElement("g", {
    filter: "url(#filter0_dd_10604_49926)"
  }, /* @__PURE__ */ React.createElement("g", {
    clipPath: "url(#clip0_10604_49926)"
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M31 2H7C4.79086 2 3 3.79086 3 6V30C3 32.2091 4.79086 34 7 34H31C33.2091 34 35 32.2091 35 30V6C35 3.79086 33.2091 2 31 2Z",
    fill: "#0966FF"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M8.43066 29.6554V6.34473H10.1017L18.791 16.3708L27.898 6.34473H29.569V29.6554H24.9737V16.8721L18.9581 23.1385L13.1095 16.8721V29.6554H8.43066Z",
    fill: "white"
  }))), /* @__PURE__ */ React.createElement("path", {
    d: "M55.555 15.2356V7.19373L52.3717 13.9791H50.6126L47.4293 7.19373V15.2356H45V3.34033H48.0157L51.4503 10.6283L54.8848 3.34033H57.9005V15.2356H55.555Z",
    fill: "#0966FF"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M70.634 5.18332L69.0424 7.11002C68.2047 6.18856 67.0319 5.60217 65.9429 5.60217C63.9324 5.60217 62.3408 7.19379 62.3408 9.28803C62.3408 11.3823 63.9324 12.9739 65.9429 12.9739C67.0319 12.9739 68.2047 12.4713 69.0424 11.5498L70.634 13.2252C69.3774 14.4818 67.5345 15.3194 65.8591 15.3194C62.3408 15.3194 59.6602 12.7226 59.6602 9.20427C59.6602 5.76971 62.4246 3.17285 66.0266 3.17285C67.702 3.25662 69.4612 4.01055 70.634 5.18332Z",
    fill: "#0966FF"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M81.0208 10.6281C81.0208 13.3925 79.0103 15.3192 76.1621 15.3192C73.2302 15.3192 71.2197 13.3925 71.2197 10.6281C71.2197 7.86371 73.2302 5.93701 76.1621 5.93701C79.0103 5.93701 81.0208 7.86371 81.0208 10.6281ZM73.8166 10.6281C73.8166 12.136 74.7381 13.1412 76.0784 13.1412C77.4187 13.1412 78.3401 12.136 78.3401 10.6281C78.3401 9.12026 77.4187 8.11502 76.0784 8.11502C74.7381 8.11502 73.8166 9.20403 73.8166 10.6281Z",
    fill: "#0966FF"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M91.9112 9.45534V15.2354H89.3143V10.2093C89.3143 9.03649 88.6441 8.36633 87.4714 8.36633C86.1311 8.36633 85.3771 9.37157 85.3771 10.7119V15.2354H82.7803V6.02078H85.3771V7.6124C86.0473 6.43963 87.1363 5.93701 88.5604 5.93701C90.5709 5.93701 91.9112 7.3611 91.9112 9.45534Z",
    fill: "#0966FF"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M100.791 6.94242L99.8695 8.70158C98.8643 8.19896 97.8591 7.86388 97.1051 7.86388C96.5188 7.86388 96.0161 8.03142 96.0161 8.53404C96.0161 9.87435 100.791 9.2042 100.791 12.4712C100.791 14.3141 99.1994 15.3194 97.1051 15.3194C95.5973 15.3194 94.1732 14.9005 93.168 14.0628L94.0057 12.3037C94.9271 13.0576 96.1837 13.4764 97.1889 13.4764C97.8591 13.4764 98.3617 13.2251 98.3617 12.7225C98.3617 11.2984 93.5868 12.0524 93.5868 8.86912C93.5868 6.94242 95.1784 6.02095 97.1889 6.02095C98.5292 5.93718 99.7858 6.27226 100.791 6.94242Z",
    fill: "#0966FF"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M111.262 15.2357H108.665V13.644C107.995 14.8168 106.99 15.3194 105.566 15.3194C103.555 15.3194 102.299 13.9791 102.299 11.8011V6.021H104.896V11.0472C104.896 12.2199 105.566 12.8901 106.571 12.8901C107.828 12.8901 108.582 11.8849 108.582 10.5446V6.021H111.178V15.2357H111.262Z",
    fill: "#0966FF"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M113.942 15.2356V2.58643H116.539V15.2356H113.942Z",
    fill: "#0966FF"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M124.832 14.733C124.162 15.1519 123.324 15.4032 122.487 15.4032C120.895 15.4032 119.638 14.4817 119.638 12.555V8.28278H118.382V6.43985H119.638V3.92676H122.235V6.43985H124.748V8.28278H122.235V12.0524C122.235 12.8063 122.57 13.1414 123.157 13.1414C123.492 13.1414 123.911 13.0576 124.413 12.8063L124.832 14.733Z",
    fill: "#0966FF"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M129.105 3.42408C129.105 4.26178 128.519 4.84817 127.681 4.84817C126.843 4.84817 126.257 4.26178 126.257 3.42408C126.257 2.58639 126.843 2 127.681 2C128.519 2 129.105 2.58639 129.105 3.42408ZM126.424 15.2356V6.02094H129.021V15.1518H126.424V15.2356Z",
    fill: "#0966FF"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M140.665 9.45534V15.2354H138.068V10.2093C138.068 9.03649 137.398 8.36633 136.225 8.36633C134.885 8.36633 134.131 9.37157 134.131 10.7119V15.2354H131.534V6.02078H134.131V7.6124C134.801 6.43963 135.89 5.93701 137.314 5.93701C139.325 5.93701 140.665 7.3611 140.665 9.45534Z",
    fill: "#0966FF"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M151.471 14.398C151.471 16.9948 149.545 18.6702 146.613 18.6702C145.021 18.6702 143.681 18.1676 142.675 17.4974L143.681 15.6545C144.518 16.2409 145.524 16.576 146.529 16.576C148.037 16.576 148.958 15.7383 148.958 14.5655V13.5603C148.372 14.398 147.45 14.9006 146.194 14.9006C143.848 14.9006 142.173 13.1414 142.173 10.4608C142.173 7.86393 143.764 6.10477 146.11 6.10477C147.367 6.10477 148.288 6.52361 148.958 7.44508V6.021H151.555V14.398H151.471ZM148.874 10.377C148.874 8.95293 148.037 8.03147 146.696 8.03147C145.356 8.03147 144.518 9.0367 144.518 10.377C144.518 11.8011 145.356 12.7226 146.696 12.7226C148.037 12.7226 148.874 11.8011 148.874 10.377Z",
    fill: "#0966FF"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M50.1102 18.8379C53.1259 18.8379 54.885 20.3457 54.885 22.9426C54.885 25.707 53.2096 27.2986 50.1102 27.2986H47.7646V30.7332H45.084V18.8379H50.1102ZM47.7646 25.1206H50.0264C51.5342 25.1206 52.3719 24.4505 52.3719 23.1101C52.3719 21.7698 51.5342 21.0997 50.0264 21.0997H47.7646V25.1206Z",
    fill: "#0966FF"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M59.1574 23.2777C59.8276 22.1049 60.8328 21.5186 62.1731 21.5186V23.9479C60.3302 23.7803 59.1574 24.7856 59.1574 26.2934V30.7332H56.5605V21.6023H59.1574V23.2777Z",
    fill: "#0966FF"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M71.9734 26.9636H65.4394C65.7745 28.1364 66.6122 28.8065 67.785 28.8065C68.7064 28.8065 69.4604 28.4714 70.1305 27.8013L71.4708 29.2254C70.5494 30.2306 69.209 30.817 67.5337 30.817C64.6017 30.817 62.7588 28.9741 62.7588 26.2097C62.7588 23.4453 64.6855 21.5186 67.4499 21.5186C70.7169 21.5186 72.2248 23.6966 71.9734 26.9636ZM69.5441 25.4557C69.4604 24.1992 68.7064 23.4453 67.5337 23.4453C66.3609 23.4453 65.607 24.1992 65.3556 25.4557H69.5441Z",
    fill: "#0966FF"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M83.6175 26.2097C83.6175 28.9741 81.9421 30.817 79.3452 30.817C78.0887 30.817 77.0834 30.3144 76.4133 29.3929V34.0002H73.8164V21.6023H76.4133V22.9426C77.0834 22.0212 78.0887 21.5186 79.2614 21.5186C81.8583 21.5186 83.6175 23.4453 83.6175 26.2097ZM80.9368 26.1259C80.9368 24.618 80.0154 23.6128 78.675 23.6128C77.3347 23.6128 76.4133 24.618 76.4133 26.1259C76.4133 27.6337 77.3347 28.639 78.675 28.639C80.0154 28.639 80.9368 27.6337 80.9368 26.1259Z",
    fill: "#0966FF"
  }), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("filter", {
    id: "filter0_dd_10604_49926",
    x: "0",
    y: "0",
    width: "38",
    height: "38",
    filterUnits: "userSpaceOnUse",
    colorInterpolationFilters: "sRGB"
  }, /* @__PURE__ */ React.createElement("feFlood", {
    floodOpacity: "0",
    result: "BackgroundImageFix"
  }), /* @__PURE__ */ React.createElement("feColorMatrix", {
    in: "SourceAlpha",
    type: "matrix",
    values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
    result: "hardAlpha"
  }), /* @__PURE__ */ React.createElement("feOffset", {
    dy: "1"
  }), /* @__PURE__ */ React.createElement("feGaussianBlur", {
    stdDeviation: "1"
  }), /* @__PURE__ */ React.createElement("feColorMatrix", {
    type: "matrix",
    values: "0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"
  }), /* @__PURE__ */ React.createElement("feBlend", {
    mode: "normal",
    in2: "BackgroundImageFix",
    result: "effect1_dropShadow_10604_49926"
  }), /* @__PURE__ */ React.createElement("feColorMatrix", {
    in: "SourceAlpha",
    type: "matrix",
    values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
    result: "hardAlpha"
  }), /* @__PURE__ */ React.createElement("feOffset", {
    dy: "1"
  }), /* @__PURE__ */ React.createElement("feGaussianBlur", {
    stdDeviation: "1.5"
  }), /* @__PURE__ */ React.createElement("feColorMatrix", {
    type: "matrix",
    values: "0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"
  }), /* @__PURE__ */ React.createElement("feBlend", {
    mode: "normal",
    in2: "effect1_dropShadow_10604_49926",
    result: "effect2_dropShadow_10604_49926"
  }), /* @__PURE__ */ React.createElement("feBlend", {
    mode: "normal",
    in: "SourceGraphic",
    in2: "effect2_dropShadow_10604_49926",
    result: "shape"
  })), /* @__PURE__ */ React.createElement("clipPath", {
    id: "clip0_10604_49926"
  }, /* @__PURE__ */ React.createElement("rect", {
    x: "3",
    y: "2",
    width: "32",
    height: "32",
    rx: "4",
    fill: "white"
  }))));
};
var LogoIcon = (props) => /* @__PURE__ */ React.createElement(Icon, __spreadValues({
  component: LogoIconSvg
}, props));
var LogoIcon_default = LogoIcon;

export {
  LogoIcon_default
};
