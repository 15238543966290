// src/antd/AppShellCore.styles.ts
import { Layout } from "antd";
import styled from "styled-components";
var LayoutStyles = styled(Layout)`
  .right-side.ant-layout {
    margin-left: 256px;
  }

  .right-side-ml-0.ant-layout {
    margin-left: 0;
  }

  .header-small-size {
    display: none;
  }

  .ant-layout-header {
    border-bottom: 1px solid #EAECF0;
    position: sticky;
    top: 0;
    z-index: 100;
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .site-layout-background {
    min-height: 360px;
    height: 100%;
    max-width: calc(90em - 232px);
    margin: auto;
    padding: 24px;
  }


  @media (max-width: 992px) {
    //side bar
    .ant-layout-sider.ant-layout-sider-dark {
      display: none !important;
    }

    .site-layout-background {
      padding: 12px;
    }

    .right-side.ant-layout {
      margin-left: 0;
    }

    //header
    .ant-layout-header {
      padding: 0 12px;
      justify-content: space-between;

      .header-small-size {
        display: block;
      }

      .header-large-size {
        display: none;
      }

      .ant-btn.ant-btn-default.header-small-size {
        border: none;
        box-shadow: none;
      }
    }
  }



`;

export {
  LayoutStyles
};
