// src/antd/Component/DrawerForUnderLargeSize/DrawerForUnderLargeSize.Styles.ts
import { Drawer } from "antd";
import styled from "styled-components";
var DrawerStyles = styled(Drawer)`

  .ant-drawer-content-wrapper {
    min-width: 100% !important;
  }


  .ant-drawer-header {
    padding: 0;
    min-height: 72px;
    max-height: 72px;
    visibility: hidden;
  }

  .ant-drawer-body {
    padding: 0;
    background-color: #101828;
  }
`;

export {
  DrawerStyles
};
