import { useQuery } from "react-query";

import { mApi } from "../services";

export const useQuestion = (
  questionId: string,
  caseId: string,
  blockId: string,
) => useQuery(
    ["getQuestionControllerGet", caseId, blockId, questionId],
    () => mApi.v1.getQuestionControllerGet(caseId, blockId, questionId),
    { enabled: !!questionId && !!caseId && !!blockId },
  );
