import { useQuery } from "react-query";

import { mApi } from "../services";

export const useBlock = (caseId: string, blockId: string) => useQuery(
    ["getBlockControllerGet", caseId, blockId],
    () => mApi.v1.getBlockControllerGet(caseId, blockId, {
        include: ["entry"],
      }),
    { enabled: !!blockId && !!caseId },
  );
