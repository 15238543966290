// src/antd/Logo/Logo.styles.ts
import styled from "styled-components";
var LogoWrap = styled.div`
  height: 64px;
  padding: 16px 0;
  cursor: pointer;
  .anticon {
    height: 100%;
    svg {
      height: 100%;
    }
  }
`;

export {
  LogoWrap
};
