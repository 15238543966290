// src/antd/Component/Sidebar/Sidebar.styles.ts
import { Layout, Menu } from "antd";
import styled from "styled-components";
var { Sider } = Layout;
var MenuWrap = styled(Menu)`
  border-right: 0;
  padding: 8px;
  padding-top: 16px;
  .ant-menu-item, .ant-menu-title-content {
    font-weight: 600;
    color: #F2F4F7;
  }
  .ant-menu-item-selected {
    border-radius: 6px;
  }
`;
var SidebarWrap = styled(Sider)`
  box-shadow: 8px 0 8px -8px rgba(0, 0, 0, 0.15);
  max-width: 256px !important;
  width: 256px !important;
  overflow: auto;
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
`;

export {
  MenuWrap,
  SidebarWrap
};
