import React, { Fragment, ReactNode, useCallback } from "react";

import { LaptopOutlined, UsergroupAddOutlined, UserOutlined, WalletOutlined } from "@ant-design/icons";
import { REFETCH_INTERVAL } from "@mcp/constants";
import { AppShellCore } from "@mcp/core";
import { OnErrorResponse, QueryDataResponse, RoleEnum } from "@mcp/interfaces";
import {
  BlockWithRelationsResponse,
  CaseWithRelationsResponse,
  QuestionWithRelationsResponse,
} from "@mcp/services/src/services";
import { getItemSideBar } from "@mcp/utils";
import { useRouter } from "next/router";
import { useErrorHandler } from "react-error-boundary";
import { useMutation, useQuery, useQueryClient } from "react-query";

import Breadcrumb from "@/components/Breadcrumb";
import { useBlock } from "@/hooks/useBlock";
import { useCase } from "@/hooks/useCase";
import { useQuestion } from "@/hooks/useQuestion";
import { FileIcon, ImageIcon, ReceiptCheckIcon } from "@/icons";
import { mApi } from "@/services";

function AuthLoader({
                      children,
                      auth,
                      sider = true,
                    }: {
  children?: ReactNode;
  auth?:
    | {
    role?: "user" | "administrator";
    loading?: React.ReactNode;
    unauthorized?: string;
  }
    | boolean;
  sider?: boolean
}) {
  const router = useRouter();
  const queryClient = useQueryClient();
  const handleError = useErrorHandler();

  const [caseId, setCaseId] = React.useState<string>("");
  const [blockId, setBlockId] = React.useState<string>("");
  const [questionId, setQuestionId] = React.useState<string>("");

  const { data: resCase } = useCase(caseId);
  const { data: resBlock } = useBlock(caseId, blockId);
  const { data: resQuestion } = useQuestion(questionId, caseId, blockId);

  const sideLinks = [
    // getItemSideBar("Dashboard", "/", <DashboardOutlined />),
    getItemSideBar("Casebot", "/casebot", <LaptopOutlined />),
    // getItemSideBar("User", "/user", <UserOutlined />, [
    //   getItemSideBar("User Index", "/user/overview"),
    // ]),
    // getItemSideBar("Product", "/products", <FormOutlined />),
    getItemSideBar("Media", "/media/admin", <ImageIcon />),
    // getItemSideBar("Media", "/media", <ImageIcon />, [
    //   getItemSideBar("Media Creator", "/media/user"),
    //   getItemSideBar("My Media", "/media/admin"),
    // ]),
    getItemSideBar("Article", "/articles", <FileIcon />),
    getItemSideBar("Order Management", "/orders", <ReceiptCheckIcon />),
    getItemSideBar("User Management", "/user", <UserOutlined />),
    getItemSideBar("Affiliate", "/affiliate", <WalletOutlined />),
    getItemSideBar("Coaches", "/coach", <UsergroupAddOutlined />),
    getItemSideBar("Meeting Management", "/meeting-management"),
    getItemSideBar("Revenue Tracking", "/revenue-tracking"),
    getItemSideBar("Redrock", "/redrock"),
    getItemSideBar("Math Drills", "/math-drills"),
    getItemSideBar("Redirections", "/redirections"),
  ];

  const { data, isLoading } = useQuery(
    "getProfileControllerProfile",
    mApi.v1.getProfileControllerProfile,
    {
      retry: 0,
      refetchInterval: REFETCH_INTERVAL,
      onError: (error) => {
        const err = error as OnErrorResponse;
        const statusCode = err?.response?.data?.statusCode;
        console.log("error", statusCode);
        // TODO: handle error
      },
    },
  );
  useQuery("sharedControllerGetOptions", mApi.v1.sharedControllerGetOptions);
  const signoutMutation = useMutation(
    "logoutHttpControllerLogout",
    mApi.v1.logoutHttpControllerLogout,
  );

  const isUser = !!data?.data;

  const onSignOut = async () => {
    signoutMutation.mutate(
      {},
      {
        onSuccess: async () => {
          const callbackUrl = encodeURIComponent(window.location.href);
          await router.push({
            pathname: process.env.ACCOUNT_URL as string,
            query: { callback_url: callbackUrl },
          });
        },
        onError: (error: any) => {
          const err = error as OnErrorResponse;
          console.error(err.response?.data?.message);
        },
      },
    );
  };
  const getTextGenerator = useCallback(
    (param: any, query: any) =>
      // @ts-ignore
      // eslint-disable-next-line implicit-arrow-linebreak
      ({
        caseId: async () => {
          const caseDetail = queryClient.getQueryData<QueryDataResponse<CaseWithRelationsResponse>>(["getCaseControllerGet", query.caseId]); // Lấy thông tin của 1 case từ bộ nhớ cache
          if (caseDetail) {
            return caseDetail?.data?.name;
          }
          setCaseId(query.caseId); // Nêu cache không có thì set lại caseId và refetch
          return resCase?.data?.name;
        },
        blockId: async () => {
          const blockDetail = queryClient.getQueryData<QueryDataResponse<BlockWithRelationsResponse>>(["getBlockControllerGet", query.caseId, query.blockId]); // Lấy thông tin của 1 block từ bộ nhớ cache
          if (blockDetail) {
            return blockDetail?.data.name;
          }
          setBlockId(query.blockId); // Nêu cache không có thì set lại caseId và refetch
          return resBlock?.data?.name;
        },
        questionId: async () => {
          const questionDetail = queryClient.getQueryData<QueryDataResponse<QuestionWithRelationsResponse>>([
            "getQuestionControllerGet",
            query.caseId,
            query.blockId,
            query.questionId,
          ]); // Lấy thông tin của 1 question từ bộ nhớ cache
          if (questionDetail) {
            return questionDetail?.data?.name;
          }
          setQuestionId(query.questionId); // Nêu cache không có thì set lại caseId và refetch
          return resQuestion?.data?.name;
        },
      }[param]),
    [queryClient, resCase?.data, resBlock?.data, resQuestion?.data],
  );

  React.useEffect(() => {
    (async () => {
      if (isLoading) return;

      if (!isUser) {
        const callbackUrl = encodeURIComponent(window.location.href);
        await router.push({
          pathname: `${process.env.ACCOUNT_URL}/signin`,
          query: { callback_url: callbackUrl },
        });
        return;
      }
      if (data?.data.role !== RoleEnum.admin) {
        await router.push("/403");
      }
    })();
  }, [isLoading, isUser, auth]);

  if (isUser) {
    if (sider) {
      return (
        <AppShellCore
          profile={data?.data.profile}
          sideLinks={sideLinks}
          signout={onSignOut}
          breadcrumb={<Breadcrumb getTextGenerator={getTextGenerator} />}
        >
          {children}
        </AppShellCore>
      );
    }
    return <>{children}</>;
  }

  return null;
}

export default AuthLoader;
