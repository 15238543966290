import React from "react";

import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const WarningSvg = () => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEE4E2" />
    <path d="M28 24V28M28 32H28.01M38 28C38 33.5228 33.5228 38 28 38C22.4772 38 18 33.5228 18 28C18 22.4772 22.4772 18 28 18C33.5228 18 38 22.4772 38 28Z" stroke="#D92D20" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <rect x="4" y="4" width="48" height="48" rx="24" stroke="#FEF3F2" strokeWidth="8" />
  </svg>
);

const WarningIcon = (props: Partial<CustomIconComponentProps>) => {
  return (
    <Icon component={WarningSvg} {...props} />
  );
};

export default WarningIcon;
