import {
  __spreadValues
} from "./chunk-NXJS66U5.mjs";

// src/antd/icons/Menu01Icon.tsx
import React from "react";
import Icon from "@ant-design/icons";
var Menu01IconSvg = () => {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "24",
    height: "24",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M3 12H21M3 6H21M3 18H21",
    stroke: "currentColor",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }));
};
var Menu01Icon = (props) => /* @__PURE__ */ React.createElement(Icon, __spreadValues({
  component: Menu01IconSvg
}, props));
var Menu01Icon_default = Menu01Icon;

export {
  Menu01Icon_default
};
