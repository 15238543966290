import {
  DrawerStyles
} from "./chunk-MSH226ZE.mjs";

// src/antd/Component/DrawerForUnderLargeSize/index.tsx
import React from "react";
import { Menu } from "antd";
import { useRouter } from "next/router";
var DrawerForUnderLargeSize = ({ sideLinks, isOpen, onClose = () => {
} }) => {
  const router = useRouter();
  const onSelectMenu = (path) => {
    onClose();
    router.push(path);
  };
  return /* @__PURE__ */ React.createElement(DrawerStyles, {
    placement: "left",
    visible: isOpen,
    zIndex: 99,
    style: { minWidth: "100%" }
  }, /* @__PURE__ */ React.createElement(Menu, {
    onClick: (item) => onSelectMenu(item.key),
    mode: "inline",
    theme: "dark",
    items: sideLinks
  }));
};
var DrawerForUnderLargeSize_default = DrawerForUnderLargeSize;

export {
  DrawerForUnderLargeSize_default
};
