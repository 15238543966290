import {
  LogoWrap
} from "./chunk-3D75XFK3.mjs";

// src/antd/Logo/index.tsx
import React from "react";
import Icon from "@ant-design/icons";
var Logo = () => /* @__PURE__ */ React.createElement("svg", {
  width: "200",
  height: "38",
  viewBox: "0 0 200 38",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}, /* @__PURE__ */ React.createElement("g", {
  filter: "url(#filter0_dd_4945_208310)"
}, /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#clip0_4945_208310)"
}, /* @__PURE__ */ React.createElement("path", {
  d: "M31 2H7C4.79086 2 3 3.79086 3 6V30C3 32.2091 4.79086 34 7 34H31C33.2091 34 35 32.2091 35 30V6C35 3.79086 33.2091 2 31 2Z",
  fill: "#F8FAFF"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M8.43082 29.6554V6.34473H10.1018L18.7911 16.3708L27.8982 6.34473H29.5692V29.6554H24.9739V16.8721L18.9582 23.1385L13.1097 16.8721V29.6554H8.43082Z",
  fill: "#0966FF"
}))), /* @__PURE__ */ React.createElement("path", {
  d: "M55.555 15.2356V7.19373L52.3717 13.9791H50.6126L47.4293 7.19373V15.2356H45V3.34033H48.0157L51.4503 10.6283L54.8848 3.34033H57.9005V15.2356H55.555V15.2356Z",
  fill: "#F8FAFF"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M70.6335 5.18332L69.0419 7.11002C68.2042 6.18856 67.0314 5.60217 65.9424 5.60217C63.9319 5.60217 62.3403 7.19379 62.3403 9.28803C62.3403 11.3823 63.9319 12.9739 65.9424 12.9739C67.0314 12.9739 68.2042 12.4713 69.0419 11.5498L70.6335 13.2252C69.377 14.4818 67.534 15.3194 65.8586 15.3194C62.3403 15.3194 59.6597 12.7226 59.6597 9.20427C59.6597 5.76971 62.4241 3.17285 66.0262 3.17285C67.7016 3.25662 69.4607 4.01055 70.6335 5.18332Z",
  fill: "#F8FAFF"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M81.0209 10.6284C81.0209 13.3928 79.0105 15.3195 76.1623 15.3195C73.2304 15.3195 71.2199 13.3928 71.2199 10.6284C71.2199 7.86396 73.2304 5.93726 76.1623 5.93726C79.0105 5.93726 81.0209 7.86396 81.0209 10.6284ZM73.8168 10.6284C73.8168 12.1362 74.7382 13.1414 76.0785 13.1414C77.4189 13.1414 78.3403 12.1362 78.3403 10.6284C78.3403 9.1205 77.4189 8.11527 76.0785 8.11527C74.7382 8.11527 73.8168 9.20427 73.8168 10.6284Z",
  fill: "#F8FAFF"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M91.911 9.45558V15.2357H89.3141V10.2095C89.3141 9.03673 88.644 8.36658 87.4712 8.36658C86.1309 8.36658 85.377 9.37181 85.377 10.7121V15.2357H82.7801V6.02103H85.377V7.61265C86.0471 6.43987 87.1361 5.93726 88.5602 5.93726C90.5707 5.93726 91.911 7.36134 91.911 9.45558Z",
  fill: "#F8FAFF"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M100.791 6.94242L99.8691 8.70158C98.8639 8.19896 97.8586 7.86388 97.1047 7.86388C96.5183 7.86388 96.0157 8.03142 96.0157 8.53404C96.0157 9.87435 100.791 9.2042 100.791 12.4712C100.791 14.3141 99.199 15.3194 97.1047 15.3194C95.5969 15.3194 94.1728 14.9005 93.1675 14.0628L94.0052 12.3037C94.9267 13.0576 96.1832 13.4764 97.1885 13.4764C97.8586 13.4764 98.3613 13.2251 98.3613 12.7225C98.3613 11.2984 93.5864 12.0524 93.5864 8.86912C93.5864 6.94242 95.178 6.02095 97.1885 6.02095C98.5288 5.93718 99.7853 6.27226 100.791 6.94242Z",
  fill: "#F8FAFF"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M111.262 15.2357H108.665V13.644C107.995 14.8168 106.99 15.3194 105.565 15.3194C103.555 15.3194 102.298 13.9791 102.298 11.8011V6.021H104.895V11.0472C104.895 12.2199 105.565 12.8901 106.571 12.8901C107.827 12.8901 108.581 11.8849 108.581 10.5446V6.021H111.178V15.2357H111.262Z",
  fill: "#F8FAFF"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M113.942 15.2356V2.58643H116.539V15.2356H113.942V15.2356Z",
  fill: "#F8FAFF"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M124.832 14.733C124.162 15.1519 123.325 15.4032 122.487 15.4032C120.895 15.4032 119.639 14.4817 119.639 12.555V8.28278H118.382V6.43985H119.639V3.92676H122.236V6.43985H124.749V8.28278H122.236V12.0524C122.236 12.8063 122.571 13.1414 123.157 13.1414C123.492 13.1414 123.911 13.0576 124.414 12.8063L124.832 14.733Z",
  fill: "#F8FAFF"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M129.105 3.42408C129.105 4.26178 128.518 4.84817 127.681 4.84817C126.843 4.84817 126.257 4.26178 126.257 3.42408C126.257 2.58639 126.843 2 127.681 2C128.518 2 129.105 2.58639 129.105 3.42408ZM126.424 15.2356V6.02094H129.021V15.1518H126.424V15.2356Z",
  fill: "#F8FAFF"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M140.665 9.45558V15.2357H138.068V10.2095C138.068 9.03673 137.398 8.36658 136.225 8.36658C134.885 8.36658 134.131 9.37181 134.131 10.7121V15.2357H131.534V6.02103H134.131V7.61265C134.801 6.43987 135.89 5.93726 137.314 5.93726C139.325 5.93726 140.665 7.36134 140.665 9.45558Z",
  fill: "#F8FAFF"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M151.471 14.398C151.471 16.9948 149.545 18.6702 146.613 18.6702C145.021 18.6702 143.681 18.1676 142.675 17.4974L143.681 15.6545C144.518 16.2409 145.524 16.576 146.529 16.576C148.037 16.576 148.958 15.7383 148.958 14.5655V13.5603C148.372 14.398 147.45 14.9006 146.194 14.9006C143.848 14.9006 142.173 13.1414 142.173 10.4608C142.173 7.86393 143.764 6.10477 146.11 6.10477C147.367 6.10477 148.288 6.52361 148.958 7.44508V6.021H151.555V14.398H151.471ZM148.874 10.377C148.874 8.95293 148.037 8.03147 146.696 8.03147C145.356 8.03147 144.518 9.0367 144.518 10.377C144.518 11.8011 145.356 12.7226 146.696 12.7226C148.037 12.7226 148.874 11.8011 148.874 10.377Z",
  fill: "#F8FAFF"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M50.1099 18.8376C53.1257 18.8376 54.8848 20.3455 54.8848 22.9424C54.8848 25.7068 53.2094 27.2984 50.1099 27.2984H47.7644V30.7329H45.0838V18.8376H50.1099ZM47.7644 25.1204H50.0262C51.534 25.1204 52.3717 24.4502 52.3717 23.1099C52.3717 21.7696 51.534 21.0994 50.0262 21.0994H47.7644V25.1204Z",
  fill: "#F8FAFF"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M59.1571 23.2775C59.8272 22.1047 60.8325 21.5183 62.1728 21.5183V23.9476C60.3298 23.7801 59.1571 24.7853 59.1571 26.2932V30.733H56.5602V21.6021H59.1571V23.2775Z",
  fill: "#F8FAFF"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M71.9738 26.9633H65.4398C65.7749 28.1361 66.6126 28.8063 67.7853 28.8063C68.7068 28.8063 69.4607 28.4712 70.1309 27.801L71.4712 29.2251C70.5497 30.2304 69.2094 30.8167 67.534 30.8167C64.6021 30.8167 62.7592 28.9738 62.7592 26.2094C62.7592 23.445 64.6859 21.5183 67.4503 21.5183C70.7173 21.5183 72.2251 23.6963 71.9738 26.9633ZM69.5445 25.4555C69.4607 24.1989 68.7068 23.445 67.534 23.445C66.3612 23.445 65.6073 24.1989 65.356 25.4555H69.5445Z",
  fill: "#F8FAFF"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M83.6178 26.2094C83.6178 28.9738 81.9424 30.8167 79.3455 30.8167C78.089 30.8167 77.0838 30.3141 76.4136 29.3927V34H73.8167V21.6021H76.4136V22.9424C77.0838 22.0209 78.089 21.5183 79.2618 21.5183C81.8586 21.5183 83.6178 23.445 83.6178 26.2094ZM80.9372 26.1256C80.9372 24.6178 80.0157 23.6126 78.6754 23.6126C77.3351 23.6126 76.4136 24.6178 76.4136 26.1256C76.4136 27.6335 77.3351 28.6387 78.6754 28.6387C80.0157 28.6387 80.9372 27.6335 80.9372 26.1256Z",
  fill: "#F8FAFF"
}), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("filter", {
  id: "filter0_dd_4945_208310",
  x: "0",
  y: "0",
  width: "38",
  height: "38",
  filterUnits: "userSpaceOnUse",
  colorInterpolationFilters: "sRGB"
}, /* @__PURE__ */ React.createElement("feFlood", {
  floodOpacity: "0",
  result: "BackgroundImageFix"
}), /* @__PURE__ */ React.createElement("feColorMatrix", {
  in: "SourceAlpha",
  type: "matrix",
  values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
  result: "hardAlpha"
}), /* @__PURE__ */ React.createElement("feOffset", {
  dy: "1"
}), /* @__PURE__ */ React.createElement("feGaussianBlur", {
  stdDeviation: "1"
}), /* @__PURE__ */ React.createElement("feColorMatrix", {
  type: "matrix",
  values: "0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"
}), /* @__PURE__ */ React.createElement("feBlend", {
  mode: "normal",
  in2: "BackgroundImageFix",
  result: "effect1_dropShadow_4945_208310"
}), /* @__PURE__ */ React.createElement("feColorMatrix", {
  in: "SourceAlpha",
  type: "matrix",
  values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
  result: "hardAlpha"
}), /* @__PURE__ */ React.createElement("feOffset", {
  dy: "1"
}), /* @__PURE__ */ React.createElement("feGaussianBlur", {
  stdDeviation: "1.5"
}), /* @__PURE__ */ React.createElement("feColorMatrix", {
  type: "matrix",
  values: "0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"
}), /* @__PURE__ */ React.createElement("feBlend", {
  mode: "normal",
  in2: "effect1_dropShadow_4945_208310",
  result: "effect2_dropShadow_4945_208310"
}), /* @__PURE__ */ React.createElement("feBlend", {
  mode: "normal",
  in: "SourceGraphic",
  in2: "effect2_dropShadow_4945_208310",
  result: "shape"
})), /* @__PURE__ */ React.createElement("clipPath", {
  id: "clip0_4945_208310"
}, /* @__PURE__ */ React.createElement("rect", {
  x: "3",
  y: "2",
  width: "32",
  height: "32",
  rx: "4",
  fill: "white"
}))));
var LogoIcon = () => /* @__PURE__ */ React.createElement(Icon, {
  component: Logo
});
function Index({ onClick }) {
  return /* @__PURE__ */ React.createElement(LogoWrap, {
    onClick
  }, /* @__PURE__ */ React.createElement(LogoIcon, null));
}
var Logo_default = Index;

export {
  Logo_default
};
