import { useQuery } from "react-query";

import { mApi } from "../services";

export const useCase = (caseId: string) => useQuery(
    ["getCaseControllerGet", caseId],
    () => mApi.v1.getCaseControllerGet(caseId, {
        include: ["problem"],
      }),
    { enabled: !!caseId },
  );
