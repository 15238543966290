import React, { useEffect, useMemo } from "react";

import { Breadcrumb as BreadcrumbAnt, Typography } from "antd";
import Link from "next/link";
import { useRouter } from "next/router";

import { BreadCrumbStyled } from "./Breadcrumb.styles";
import { BreadcrumbTypes } from "./Breadcrumb.types";

const generatePathParts = (pathStr: string) => {
  const pathWithoutQuery = pathStr.split("?")[0];
  return pathWithoutQuery.split("/").filter((v) => v.length > 0);
};

const { Text } = Typography;

function Crumb({
  isCurrent = false,
  href,
  label,
  textGenerator,
}: BreadcrumbTypes) {
  const [text, setText] = React.useState(label);

  useEffect(() => {
    (async () => {
      if (!textGenerator) {
        return;
      }
      const finalText = await textGenerator();
      setText(finalText);
    })();
  }, [textGenerator, label]);
  if (
    isCurrent
    || label.toLowerCase() === "cases"
    || label.toLowerCase() === "blocks"
    || label.toLowerCase() === "questions"
  ) {
    return (
      <BreadcrumbAnt.Item>
        <Text strong={isCurrent}>{label}</Text>
      </BreadcrumbAnt.Item>
    );
  }

  return (
    <Link href={href} passHref legacyBehavior>
      <BreadcrumbAnt.Item>
        {text}
      </BreadcrumbAnt.Item>
    </Link>
  );
}

function Breadcrumb({
  getTextGenerator,
}: {
  getTextGenerator: (param: string, query: any) => any;
}) {
  const router = useRouter();

  const breadcrumbs: BreadcrumbTypes[] = useMemo(() => {
    const pathWithoutQuery = router.asPath.split("?")[0];
    let pathArray = pathWithoutQuery.split("/");
    pathArray.shift();
    const pathnameNestedRoutes = generatePathParts(router.pathname);

    pathArray = pathArray.filter((path) => path !== "");

    const crumbs = pathArray.map((path, index) => {
      const href = `/${pathArray.slice(0, index + 1).join("/")}`;
      const label = path.charAt(0).toUpperCase() + path.slice(1);
      const param = pathnameNestedRoutes[index]
        .replace("[", "")
        .replace("]", "");

      return {
        href,
        label,
        textGenerator: getTextGenerator(param, router.query),
        isCurrent: index === pathArray.length - 1,
      };
    });

    return [{ href: "/", label: "Admin dashboard" }, ...crumbs];
  }, [router.asPath, router.pathname, router.query, getTextGenerator]);

  return (
    <BreadcrumbAnt>
      {breadcrumbs.map((breadcrumb) => (
        <Crumb key={breadcrumb.href} {...breadcrumb} />
      ))}
    </BreadcrumbAnt>
  );
}

export default Breadcrumb;
