import React, { useState } from "react";

import { PortalManager } from "@chakra-ui/portal";
import { ErrorUnknown } from "@mcp/core";
import { AppAuthProps, OnErrorResponse } from "@mcp/interfaces";
import ConfigProvider from "antd/lib/config-provider";
import locale from "antd/lib/locale/vi_VN";
import { ErrorBoundary } from "react-error-boundary";
import {
  QueryClient,
  QueryClientProvider,
  QueryErrorResetBoundary,
} from "react-query";
import {
  ReactQueryDevtools,
} from "react-query/devtools";

import "@/assets/css/antdTheme.less";
import "@/assets/css/grapes.css";

import AuthLoader from "@/components/AuthLoader";

function MyApp({ Component, pageProps }: AppAuthProps) {
  const getLayout = Component.getLayout ?? ((page) => page);

  const [queryClient] = useState(
    () => new QueryClient({
        defaultOptions: {
          queries: {
            retry: 0,
            refetchOnWindowFocus: false,
            useErrorBoundary: (error: any) => {
              const err = error as OnErrorResponse;
              return Number(err.response?.data?.statusCode) >= 500;
            },
          },
          mutations: {
            useErrorBoundary: (error: any) => {
              const err = error as OnErrorResponse;
              return Number(err.response?.data?.statusCode) >= 500;
            },
          },
        },
      }),
  );

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider locale={locale}>
        <QueryErrorResetBoundary>
          {({ reset }) => (
            <>
              {Component.auth
                ? (
                  <ErrorBoundary
                    FallbackComponent={({ resetErrorBoundary, error }) => (
                      <ErrorUnknown
                        reset={resetErrorBoundary}
                        message={error.message as string}
                      />
                      )}
                    onReset={reset}
                  >
                    <AuthLoader auth={Component.auth} sider={Component.sider}>
                      {getLayout(<Component {...pageProps} />, pageProps)}
                    </AuthLoader>
                  </ErrorBoundary>
                )
                : getLayout(<Component {...pageProps} />, pageProps)}
              <PortalManager />
            </>
          )}
        </QueryErrorResetBoundary>
      </ConfigProvider>
      <ReactQueryDevtools position="bottom-right" />
    </QueryClientProvider>
  );
}

export default MyApp;
